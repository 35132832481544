.Log-col-aircraft {
  display: flex;
  flex-direction: column;
  flex: 0;
  white-space: nowrap;
}

.Log-col-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1em;
}

.Log-col-nocoords {
  border: 2px inset red;
}

.Log-col-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0.25;
  display: block;
  z-index: 5;
}

.Log-emergency {
  background-color: #f1a1a1;
}

.Log-lifted {
  background-color: #e2f9ff;
  border-bottom: 5px dashed black;
}

.Log-landed, .Log-techstop {
  background-color: #add99b;
}

.Log-landed {
  border-top: 5px dashed black;
}

.Log-enroute {
  background-color: #b3ecf5;
}

.user-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
