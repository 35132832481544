.App {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-sidebar {
  min-height: 100vh;
  flex: 1;
  flex-direction: column;
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: auto;
}

.App-main {
  text-align: center;
  position: relative;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1;
}

.App-link {
  color: #61dafb;
}

h1 {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}

.App-logs {
  flex-direction: column;

}

.button {
  border: 2px solid black;
  border-radius: 5px;
  padding: 0.5em;
  margin-bottom: 1em;
  align-self: center;
}

input.input, select.input, textarea.input {
  @apply mt-1 rounded-md border-gray-300 shadow-sm;
  @apply focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
